@import "../color.scss";
@import "../breakpoint.scss";

.header ul {
  margin-top: 172px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @include lg {
    display: grid;
    grid-template-columns: 25% 25% 25%;
    gap: 10px;
  }
  @include sm {
    grid-template-columns: 45% 45%;
  }
  @include xl {
    gap: 3px;
  }
}
.header {
  @include lg {
    display: none;
  }
}

.header a {
  width: 100px;
  height: 40px;
  border-radius: 10px;
}

.header li {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  transition: all 0.1s ease-in-out;

  .activeLinkHome {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background-color: white;
    width: 100px;
    height: 40px;
  }
  .iconeClass {
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
.header li:hover {
  transform: scale(1.1);
}

.iconeClass6 {
  background-color: $color6;
}
.iconeClass5 {
  background-color: $color5;
}
.iconeClass4 {
  background-color: $color4;
}
.iconeClass3 {
  background-color: $color3;
}
.iconeClass2 {
  background-color: $color2;
}
.iconeClass1es {
  background-color: $color1es;
}
.iconeClass1spe {
  background-color: $color1spe;
}
.iconeClasstspe {
  background-color: $colorTes;
}
.iconeClasstes {
  background-color: $colorTspe;
}
.isActiveClass6 {
  color: $color6;
  border: 3px solid $color6;
}
.isActiveClass5 {
  color: $color5;
  border: 3px solid $color5;
}
.isActiveClass4 {
  color: $color4;
  border: 3px solid $color4;
}
.isActiveClass3 {
  color: $color3;
  border: 3px solid $color3;
}
.isActiveClass2 {
  color: $color2;
  border: 3px solid $color2;
}
.isActiveClass1es {
  color: $color1es;
  border: 3px solid $color1es;
}
.isActiveClass1spe {
  color: $color1spe;
  border: 3px solid $color1spe;
}
.isActiveClasstes {
  color: $colorTspe;
  border: 3px solid $colorTspe;
}
.isActiveClasstspe {
  color: $colorTes;
  border: 3px solid $colorTes;
}
.isActiveClassAll {
  color: $colorTes;
  border: 3px solid $colorTes;
}
