@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@mixin flexbox_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: black;
}

.active-btn {
  color: green;
}

html {
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  min-width: 200px;
  background-image: url("../../public/fond.png");
  background-attachment: fixed;
  background-size: cover;
}

html button {
  font-family: Roboto, Arial, sans-serif;
}
