.popupNewStudent {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}
.arrayOfStudent {
  display: flex;
  justify-content: center;
}
.popupNewStudentButton {
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 14px 24px;
  background-color: gray;
  color: #fff;
  margin-bottom: 25px;
  @include lg {
    margin-top: 70px;
  }
}

.studentForm {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.studentForm form {
  gap: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  align-items: center;
  margin: auto;
  width: 400px;
  padding: 20px;
  z-index: 10;
  border-radius: 10px;
  top: 20%;
}

.studentForm form input {
  border: none;
  border-bottom: 1px solid black;
}

.studentForm form input:focus {
  outline: none;
}

.arrayOfStudent {
  table {
    width: 50%;
    background-color: lightgray;
    border-radius: 2px;
  }
  td {
    text-align: center;
    padding: 10px;
  }
  th {
    padding: 10px;
  }
}

.identifiantCreate {
  margin-top: 30px;
}

.trashStudent {
  width: 22px;
  height: 22px;
}
