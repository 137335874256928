$breakpoints: (
  "xs": 0,
  "sm": 400px,
  "md": 720px,
  "lg": 960px,
  "xl": 1200px,
);

@mixin xs {
  @media (max-width: map-get($breakpoints,"xs")) {
    @content;
  }
}
@mixin sm {
  @media (max-width: map-get($breakpoints,"sm")) {
    @content;
  }
}
@mixin md {
  @media (max-width: map-get($breakpoints,"md")) {
    @content;
  }
}
@mixin lg {
  @media (max-width: map-get($breakpoints,"lg")) {
    @content;
  }
}
@mixin xl {
  @media (max-width: map-get($breakpoints,"xl")) {
    @content;
  }
}
