@keyframes headshake {
  25% {
    // entièrement à droite
    transform: translateX(6%);
  }
  75% {
    // entièrement à gauche
    transform: translateX(6% * -1);
  }
}

@keyframes openMenu {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
