@import "../breakpoint.scss";

.bodyForm {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../../../public/fond2.png");
  background-size: 200%;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
}
.CenterForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 60px 40px 60px;
  background-color: rgb(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @include md {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255, 224, 224);
  }
  h1 {
    color: black;
    text-align: center;
    margin-bottom: 25px;
  }
  input[type="text"],
  input[type="password"] {
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    @include md {
      background-color: rgba(255, 255, 255, 0);
    }
  }

  input[type="text"],
  input[type="password"]:focus {
    outline: none;
    border-bottom: 1px solid black;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
  }

  .submitLogin {
    margin-top: 25px;
    font-family: Roboto, Arial, sans-serif;
    border: none;
    height: 40px;
    width: 100%;
    font-weight: 400;
    font-size: 17px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &:hover {
      transform: scale(1.03);
      transition: all 5ms linear;
      cursor: pointer;
    }
  }
  .animationLogin {
    animation: headshake 100ms cubic-bezier(0.4, 0.1, 0.6, 0.9) 2;
  }
  .form {
    color: black;
  }
  .errorMessageFetch {
    font-size: 13px;
    font-weight: 300;
  }
}
.logoLoginPage {
  width: 200px;
  height: 200px;
  top: 80px;
  position: absolute;
  @include md {
    z-index: 10;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 80px;
  }
  @media (max-height: 900px) {
    display: none;
  }
}
