.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info {
  display: flex;
}

.thread-container ul {
  display: flex;
  flex-direction: column;
}
.post-container {
  margin-top: 50px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.157);
  width: 50%;
  min-width: 310px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 15px;
  @include lg {
    margin-top: 170px;
    width: 100%;
  }
}

.post-form textarea {
  resize: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  margin: 0% 5% 0px 5%;
  font-family: lato;
}
.post-form textarea:focus {
  outline: none;
}
.post-form {
  display: flex;
  flex-direction: column;
}
.icon {
  display: flex;
  opacity: 0.65;
}

.user-info h3 {
  font-size: 16px;
  color: black;
}
.user-info h3:hover {
  text-decoration: underline;
}

.data {
  width: 100%;
}

#file-upload {
  position: absolute;
  width: 30px;
  height: 25px;
  cursor: pointer;
  opacity: 0;
  top: 5px;
}
.btn-send {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 15px 10px;
  gap: 20px;
}
.send {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.send:hover {
  color: #fff;
  background-color: rgb(246, 166, 166);
  border-color: rgb(238, 164, 164);
}
.cancel {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: lightcoral;
  border-color: lightcoral;
}
.cancel:hover {
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
}
.icon {
  position: relative;
  width: 15px;
  height: 40px;
  margin-left: 8px;
  z-index: 1;
  margin-bottom: -15px;
}
.picture {
  padding: 0px;
  margin: 0px;
}

.card-visual {
  display: flex;
  width: 95%;
  background-color: rgba(205, 205, 205, 0.328);
  overflow: hidden;
}
.card-visual-container {
  display: flex;
  justify-content: center;
}
.card-visual-container .card-right img {
  max-width: 250px;
  max-height: 400px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.comment-icon {
  cursor: pointer;
}
