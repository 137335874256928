.main ul {
  display: flex;
  padding: 0;
  gap: 15px;
}
.card-container {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.157);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 15px;
  overflow: hidden;
  position: relative;
}

.card-left img {
  width: 45px;
  height: 45px;
  border-radius: 20px;
  margin-top: 15px;
  margin-left: 15px;
}

.pseudo h3 {
  font-size: 16px;
  margin: 0px;
  margin-top: 5px;
}

.pseudo {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 10px;
}

.pseudo img {
  width: 20px;
  height: 20px;
}

.card-footer img {
  width: 20px;
  height: 20;
}
.card-footer h6 {
  margin: 0;
  margin-left: 10px;
}
.card-right {
  width: 100%;
  margin-left: 35px;
  margin-right: 35px;
  @include lg {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.footer-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thread-container {
  margin-top: 25px;
  width: 50%;
  min-width: 310px;
  @include lg {
    width: 100%;
    margin-top: 150px;
  }
}

.card-footer {
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
}
.card-pic {
  width: 100%;
  border-radius: 10px;
}

.card-container p {
  text-align: justify;
  margin: 10px 0 10px 0;
  width: 100%;
}

.like-container {
  margin-left: 10px;
  cursor: pointer;
}
.datePost {
  font-size: 11px;
  font-weight: 300;
  color: rgb(83, 82, 82);
}
.update-post textarea {
  width: 80%;
  resize: none;
  border: none;
  border-bottom: 1px solid lightgrey;
  padding: 5px;
  margin: 0% 5% 0px 5%;
  font-family: lato;
}
.update-post textarea:focus {
  outline: none;
}

.button-container .btn {
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-container .btn:hover {
  color: #fff;
  background-color: rgb(242, 149, 149);
  border-color: rgb(236, 153, 153);
}
.comment-header {
  position: relative;
  background-color: whitesmoke;
  border-radius: 10px;
}
.left-part-comment img {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 45px;
  border-radius: 20px;
}

.comment-header h3 {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 800;
}
.comment-date {
  font-size: 11px;
  font-weight: 300;
  margin-left: 20px;
  color: rgb(83, 82, 82);
}

.comment-header p {
  text-align: justify;
  padding: 0px 20px 10px 20px;
  margin: 10px 0px 0px 0px;
  font-size: 13px;
}

.edit-comment-form input[type="text"] {
  border: none;
  font-family: lato;
  width: 90%;
  height: 40px;
  border-radius: 10px;
  margin-left: 15px;
}

.edit {
  width: 20px;
  margin-left: 12px;
}
.edit-comment-form {
  justify-content: center;
}
.edit label {
  display: flex;
  align-content: center;
}
.edit-comment-form input[type="submit"] {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: lightcoral;
  border-color: lightcoral;
}
.edit-comment-form input[type="submit"]:hover {
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
}

.edit-comment-form label {
  padding: 2px;
  margin-left: 12px;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: lightcoral;
  border-color: lightcoral;
}
.edit-comment-form label:hover {
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
}

.comment-form {
  display: flex;
  padding: 15px 0px 10px 0px;
}

.comment-form input[type="text"] {
  border: 2px solid whitesmoke;
  font-family: lato;
  width: 60%;
  height: 40px;
  border-radius: 10px 0px 0px 10px;
  margin-left: 15px;
  background-color: whitesmoke;
}

.comment-form input[type="submit"] {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: lightcoral;
  border-color: lightcoral;
  margin-right: 23px;
}
.comment-form input[type="submit"]:hover {
  color: #fff;
  background-color: lightcoral;
  border-color: lightcoral;
}

.client .comment-header {
  background-color: lightgray;
}

.edit-comment img {
  position: absolute;
  top: 10px;
  right: 10px;
}

#message {
  font-family: Roboto, Arial, sans-serif;
  padding-top: 24px;
}

#typoComment {
  font-family: Roboto, Arial, sans-serif;
}
