.socialLinkContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.instaCarrousel {
  display: none;
  margin-top: 30px;
  border-radius: 5px;
  width: 800px;
  height: 300px;
  background-color: lightgray;
  @include lg {
    margin-top: 180px;
  }
}

.secondSocialPart {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: center;
  @include lg {
    margin-top: 200px;
  }
}

.logoYT {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: lightgray;
  border-radius: 5px;
  flex-direction: column;
  cursor: pointer;

  @include lg {
    width: 200px;
    height: 200px;
  }
}

.logoInsta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: lightgray;
  border-radius: 5px;
  cursor: pointer;
  @include lg {
    width: 200px;
    height: 200px;
  }
}

.socialLinkContainer img {
  width: 100px;
  height: 100px;
}
