@import "../color.scss";

.CardThemeContainer {
  margin-top: 10px;
}
.contentContainer {
  margin-top: 70px;
  @include lg {
    margin-top: 120px;
  }
}

.themeContainer ul {
  display: flex;
  @include md {
    font-size: 14px;
  }
}
.ThemeElementContainer {
  display: flex;
  justify-content: center;
}
.ChapterElementContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}
.themeline {
  background-color: rgba(172, 170, 170);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 70%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  height: 55px;
  margin-top: 50px;
  @include lg {
    width: 100%;
    border-radius: 0;
  }
}
.chapterline {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 23px;
  position: relative;
  width: 92%;
  background-color: rgb(202, 197, 197);
  color: black;
  border-radius: 5px;
  cursor: pointer;

  @include lg {
    width: 100%;
    border-radius: 0;
  }
}

.modifyAndDeleteTheme {
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 16px;
}

.modifyAndDeleteTheme img {
  width: 20px;
  height: 20px;
}

.ShowTheme {
  @include lg {
    margin-left: 0;
    margin-top: 100px;
  }
}

.themeTitle {
  background-color: $color6;
  height: 57px;
  width: 56px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border-radius: 5px 0 0 5px;
  @include lg {
    border-radius: 0;
  }
}
.themeDescription {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  margin-left: 20px;
  color: white;
  @include sm {
    font-size: 18px;
  }
}

.chapterContainer {
  display: flex;
  justify-content: center;
}

.selectClassgame {
  background-color: $colorGame;
}
.selectClassboxoutils {
  background-color: $colorOutil;
}
.selectClassbrevet {
  background-color: $colorBrevet;
}
.selectClassbac {
  background-color: $colorBac;
}
.selectClassorientation {
  background-color: $colorOrientation;
}

.selectClass6° {
  background-color: $color6;
}
.selectClass5° {
  background-color: $color5;
}
.selectClass4° {
  background-color: $color4;
}
.selectClass3° {
  background-color: $color3;
}
.selectClass2° {
  background-color: $color2;
}
.selectClass1°es {
  background-color: $color1es;
}
.selectClass1°spe {
  background-color: $color1spe;
}
.selectClassT°es {
  background-color: $colorTes;
}
.selectClassTSPE {
  background-color: $colorTspe;
}
.selectPastelClass6° {
  background-color: $color6Pastel;
}
.selectPastelClass5° {
  background-color: $color5Pastel;
}
.selectPastelClass4° {
  background-color: $color4Pastel;
}
.selectPastelClass3° {
  background-color: $color3Pastel;
}
.selectPastelClass2° {
  background-color: $color2Pastel;
}
.selectPastelClass1°es {
  background-color: $color1esPastel;
}
.selectPastelClass1°spe {
  background-color: $color1spePastel;
}
.selectPastelClassT°es {
  background-color: $colorTesPastel;
}
.selectPastelClassTSPE {
  background-color: $colorTspePastel;
}
.selectPastelClassbrevet {
  background-color: $colorBrevetPastel;
}
.selectPastelClassbac {
  background-color: $colorBacPastel;
}
.selectPastelClassorientation {
  background-color: $colorOrientationPastel;
}
.selectPastelClassboxoutils {
  background-color: $colorOutilPastel;
}
.selectPastelClassgame {
  background-color: $colorGamePastel;
}

.ShowChapter {
  width: 70%;
  @include lg {
    width: 100%;
  }
}
.modifyAndDeleteThemeChapter {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 35px;
  top: 16px;
}

.modifyAndDeleteThemeChapter img {
  width: 20px;
  height: 20px;
}

.addthemebutton {
  cursor: pointer;
  display: flex;
  font-size: 30px;
  font-weight: 400;
  color: white;
  align-items: center;
  margin-top: 10px;
  border: none;
  width: 70%;
  height: 55px;
  background-color: rgb(172, 170, 170, 0.45);
  border-radius: 5px;
  font-weight: 300;
  @include lg {
    width: 100%;
  }
}

.addthemeFront {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: white;
  width: 56px;
  height: 57px;
}
.addTheme_container p {
  margin-left: 20px;
}
.update-post {
  display: flex;
}

.addchapterbutton {
  cursor: pointer;
  display: flex;
  font-size: 23px;
  font-weight: 400;
  color: white;
  align-items: center;
  margin-top: 10px;
  border: none;
  width: 92%;
  height: 50px;
  border-radius: 5px;
  background-color: rgba(172, 170, 170, 0.45);
  @include lg {
    width: 100%;
    margin-left: 55px;
  }
}

.addChapterContainer {
  display: flex;
  justify-content: flex-end;
}

.addChapterFront {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: white;
  width: 50px;
  height: 50px;
}

.addChapterContainer p {
  margin-left: 20px;
}

.addChapter_container {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.addTheme_container {
  display: flex;
  justify-content: center;
}

.newthemeFormContainer {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.select6° {
  color: $color6;
}
.select5° {
  color: $color5;
}
.select4° {
  color: $color4;
}
.select3° {
  color: $color3;
}
.select2° {
  color: $color2;
}
.select1°es {
  color: $color1es;
}
.select1°spe {
  color: $color1spe;
}
.selectT°es {
  color: $colorTes;
}
.selectTSPE {
  color: $colorTspe;
}
.chapterTitle {
  margin-left: 20px;
}

.newThemeForm {
  position: relative;
  top: 20%;
  display: flex;
  width: 400px;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.newThemeForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newThemeForm h2 {
  margin-bottom: 20px;
}

#themeTitle:focus {
  outline: none;
}

#themeTitle {
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
#themedescription {
  border: none;
  margin-bottom: 20px;

  border-bottom: 1px solid black;
}
#themedescription:focus {
  outline: none;
}

.submitNewTheme {
  border: none;
  width: 200px;
  height: 30px;
}

.newChapterForm {
  position: relative;
  top: 20%;
  display: flex;
  width: 400px;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.newChapterForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newChapterForm h2 {
  margin-bottom: 20px;
}

#chapterTitle:focus {
  outline: none;
}

#chapterTitle {
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
#chapterdescription {
  border: none;
  margin-bottom: 20px;

  border-bottom: 1px solid black;
}
#chapterdescription:focus {
  outline: none;
}

.submitNewChapter {
  border: none;
  width: 200px;
  height: 30px;
}
.closemodaltheme {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  font-size: 30px;
  background: none;
}
.newStudentBtn {
  background-color: lightgray;
  width: 200px;
  height: 30px;
  border-radius: 3px;
  cursor: pointer;
}
.chapterdescriptionP {
  font-weight: 300;
  margin-left: 5px;
}
