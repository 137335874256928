@import "./settings";
@import "./components/login";
@import "./components/header.scss";
@import "./keyframes.scss";
@import "./breakpoint.scss";
@import "./color.scss";
@import "./components/headerAll.scss";
@import "./components//Aheader.scss";
@import "./components/listClass.scss";
@import "./components/CardTheme.scss";
@import "./components/CardLesson.scss";
@import "./components/content.scss";
@import "./components/postCard.scss";
@import "./components/createPost.scss";
@import "./components/headerRight.scss";
@import "./components/prez.scss";
@import "./components/socialmedia.scss";

.nameOfPage {
  display: none;
}

.nameOfCurrentPage {
  display: none;
}
