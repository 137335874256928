.presentationContainer img {
  width: 350px;
  height: 350px;
  border-radius: 10px;
  object-fit: cover;
  @include md {
    margin-top: 100px;
    width: 489px;
    height: 489px;
  }
  @include sm {
    width: 100%;
    height: 100%;
  }
}

.presentationContainer {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  @include lg {
    margin-top: 150px;
  }
  @include md {
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
}

.prezFirstPart {
  width: 350px;
  h2 {
    text-align: center;
  }
  @include md {
    width: 489px;
  }
  @include sm {
    width: 100%;
    height: 100%;
  }
}

.secondBlockPrez {
  width: 489px;
  background-color: lightgray;
  border-radius: 10px;
  margin-left: 25px;
  @include md {
    margin-left: 0;
    margin-top: 20px;
  }
  h2 {
    text-align: center;
  }
  @include sm {
    width: 100%;
    height: 100%;
  }
}

.secondBlockPrez p {
  padding: 30px;
  text-align: justify;
  font-size: 16px;
}

.firstBlockPrez {
  background-color: lightgray;
  border-radius: 10px;
  margin-top: 20px;
}

.firstBlockPrez p {
  font-size: 16px;
  padding: 30px;
  text-align: justify;
}

.titleSecondPart {
  margin-top: 20px;
}

.titlefirstPart {
  padding-top: 20px;
}
