$color6: rgb(244, 224, 112);
$color5: rgb(241, 200, 146);
$color4: rgb(239, 126, 126);
$color3: rgb(241, 146, 188);
$color2: rgb(206, 146, 241);
$color1es: rgb(146, 164, 241);
$color1spe: rgb(146, 217, 241);
$colorTspe: rgb(170, 249, 173);
$colorTes: rgb(200, 241, 146);

$colorOutil: rgb(54, 77, 57);
$colorBrevet: rgba(180, 94, 67, 0.897);
$colorBac: rgb(82, 44, 73);
$colorOrientation: rgb(13, 75, 80);
$colorGame: rgb(121, 28, 28);

$color6Pastel: rgba(244, 224, 112, 0.733);
$color5Pastel: rgb(250, 222, 186);
$color4Pastel: rgb(241, 156, 156);
$color3Pastel: rgb(245, 171, 204);
$color2Pastel: rgb(218, 174, 243);
$color1esPastel: rgb(183, 194, 241);
$color1spePastel: rgb(183, 231, 247);
$colorTspePastel: rgb(195, 250, 197);
$colorTesPastel: rgb(218, 243, 184);

$colorOutilPastel: rgba(54, 77, 57, 0.527);
$colorBrevetPastel: rgba(180, 93, 67, 0.486);
$colorBacPastel: rgba(82, 44, 73, 0.404);
$colorOrientationPastel: rgba(13, 76, 80, 0.411);
$colorGamePastel: rgba(122, 32, 32, 0.315);
