@import "../breakpoint.scss";

.headerAll {
  background-image: url("../../../public/Banniere_Site.jpg");
  height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.logoSite {
  width: 70px;
  height: 70px;
}

.logoContainer {
  width: 30px;
  height: 30px;
}
.imglogoContainer {
  width: 25px;
  height: 25px;
  transition: all 0.1s ease-in-out;
}
.imglogoContainer:hover {
  transform: scale(1.1);
}
.Home {
  position: absolute;
  top: 22px;
  right: 65px;
}
.logout {
  position: absolute;
  top: 11px;
  right: 12px;
  cursor: pointer;
}
.admin {
  position: absolute;
  top: 10px;
  right: 57px;
  height: 30px;
  width: 30px;
}
.nameOfPage {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  color: #666666;
  font-size: 30px;
  font-weight: 200;
  @include lg {
    margin-top: -40px;
  }
  @include md {
    display: none;
  }
}
.nameOfCurrentPage {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  @include md {
    display: none;
  }
}

.mobileMenuOpen {
  position: fixed;
  width: 300px;
  height: 100%;
  background-color: rgb(240, 237, 237);
  padding-left: 20px;
  padding-right: 10px;
  margin-top: 52px;
  overflow-y: scroll;
  transform: translateX(-100%);
  animation: openMenu 0.1s ease-out forwards;
}

.mobileMenuFirstPart ul {
  margin-top: 15px;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.mobileMenuFirstPart a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-weight: 500;
  @include sm {
    height: 35px;
  }
}
.mobileMenuFirstPart li {
  border-radius: 5px;
}
.mobileMenuSecondPart {
  margin-top: 50px;
  height: 100%;
  @include sm {
    margin-top: 25px;
  }
}
.mobileMenuSecondPart img {
  width: 25px;
  height: 25px;
}

.LinkRightMobile a {
  display: flex;
  align-items: center;
}

.LinkRightOne li {
  margin-left: 20px;
}

.activeLinkRightMobile li {
  margin-left: 20px;
}
.imglogoSite {
  display: none;
  cursor: pointer;
  @include lg {
    display: block;
  }
}

.logoprof {
  width: 150px;
  height: 150px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 80px;
  left: 50%;
  @include md {
    display: none;
  }
}

.menuItemDescriptionMobile {
  margin-left: 10px;
}
