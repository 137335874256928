.CardLessonsContainer {
  display: flex;
  position: relative;
  margin: 6px;
  cursor: pointer;
}
.lessonPic {
  width: 277px;
  height: 210px;
  border-radius: 20px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  object-fit: cover;
}
.lessonsContainer p {
  text-align: center;
  margin-top: 3px;
}

.eachCard {
  display: flex;
  background-color: rgb(202, 197, 197, 0.5);
  padding: 20px;
  flex-wrap: wrap;
  border-radius: 5px;
  @include md {
    justify-content: center;
  }
}

.modifyAndDeleteThemeLesson {
  display: flex;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  right: 62px;
  z-index: 1;
}
.modifyAndDeleteThemeLesson img {
  width: 20px;
  height: 20px;
}
.button-container {
  display: flex;
}

.lessonTitle {
  position: absolute;
  bottom: 4px;
  right: 0px;
  width: 279px;
  height: 51px;
  background-color: rgb(219, 217, 217, 0.85);
  border-radius: 0 0 20px 20px;
}
.ShowLesson {
  width: 92%;
  @include lg {
    width: 100%;
  }
}
.chapterLessonContainer {
  display: flex;
  flex-direction: row-reverse;
}
.newLessonButton {
  width: 277px;
  height: 210px;
  background-color: rgb(255, 255, 255, 0.4);
  border-radius: 20px;
  font-size: 100px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 5px;
  margin-left: 7px;
}
.plessonTitle {
  font-size: 22px;
  font-weight: 500;
}

.addLesson_container {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.lessonDescription {
  margin-top: 2px;
  font-size: 17px;
}
.newLessonForm {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  width: 400px;
  padding: 20px;
  top: 20%;
  z-index: 10;
  border-radius: 10px;
}

.closemodal {
  position: absolute;
  top: 13px;
  right: 20px;
  border: none;
  font-size: 30px;
  background: none;
}

.newLessonForm h2 {
  margin-bottom: 20px;
  text-align: center;
}
.newLessonForm form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#lessonTitle {
  border: none;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}
#lessonTitle:focus {
  outline: none;
}
#lessondescription {
  border: none;
  margin-bottom: 20px;

  border-bottom: 1px solid black;
}
#lessondescription:focus {
  outline: none;
}
.submitNewLesson {
  border: none;
  width: 200px;
  height: 30px;
}

#picture {
  margin-bottom: 15px;
}

.showMoreComment {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.showMoreComment:hover {
  border-bottom: 1px solid black;
}
