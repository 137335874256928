.HearderAllRight {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  @include lg {
    display: none;
  }
}

.HearderAllRight img {
  width: 35px;
  height: 35px;
  @media (max-height: 700px) {
    width: 25px;
    height: 25px;
  }
}
.LinkRight a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 10;
  @media (max-height: 700px) {
    width: 35px;
    height: 35px;
  }
}

.headerRightElement {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.menuItemDescription {
  background-color: white;
  margin-left: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  display: none;
}

.LinkRight:hover + .menuItemDescription {
  display: block;
}
.activeLinkRight {
  background-color: lightgray !important;
}

.LinkRight {
  transition: all 0.1s ease-in-out;
}

.LinkRight:hover {
  transform: scale(1.1);
}
